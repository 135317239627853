import { TObject, TProperties, Type } from "@sinclair/typebox";
import {
  ARMRepriceFrequencyType,
  ARMRepriceLookbackType,
  ARMRepriceRoundingMethodType,
  ARMRepriceRoundingRatePercentType,
  AchExemptionStatusType,
  AmortizationType,
  BooleanSelectType,
  CapitalStructureType,
  ChannelType,
  CollateralPackageStatusType,
  Deal,
  DealExceptionApprovedBy,
  DealMilestoneType,
  DealOperationsDepartmentType,
  DebtStatusType,
  ExceptionPolicy,
  ExceptionType,
  ExecutionType,
  FixNFlipNBridgePlusTierType,
  FloodInsurancePremiumPaymentType,
  FundingShieldStatusType,
  IndexSourceType,
  IntegrationType,
  InterestAccrualMethodType,
  LenderEntityType,
  LenderIdentifierType,
  LoanPaymentType,
  LoanProductType,
  LoanProgramType,
  LoanPurposeType,
  PrepaymentPenaltyType,
  PricingEngineExceptionStatusType,
  PropertyInsurancePremiumPaymentType,
  PropertyManagerType,
  PropertyModelType,
  PropertyRightsOwnershipType,
  RealEstateProgramType,
  RecourseType,
  ShippingCompanyType,
  TitleVestingType,
  UnderwriterApprovalStatusType,
  WarehouseBankStatusType,
  WarehouseBankType,
  getDealTemplate
} from "../entities";
import { WireInsuranceVendorType } from "../entities/task-types/integrations/wire-insurance-certificate/wireInsuranceVendor.types";
import {
  AggregationFieldSchema,
  ThirdPartyFieldSchema
} from "./aggregation.typebox";
import { BaseEntitySchema } from "./baseEntity.typebox";
import { FieldExceptionTemplateSchema } from "./fieldExceptions.typebox";
import { StateCodeSchema, StateNameSchema } from "./location.typebox";
import { OmitCreateSchema, OmitUpdateSchema } from "./service.typebox";
import {
  ArrayField,
  BooleanField,
  DateField,
  Deprecated,
  EmailField,
  EnumField,
  IntegerField,
  MoneyAmountField,
  MoneyAmountFieldString,
  NumberField,
  PercentField,
  PercentFieldString,
  PhoneNumberField,
  PostalCodeField,
  StringField,
  ThreeDecimalPercentField,
  ThreeDecimalPercentFieldString
} from "./utils.typebox";
import { FieldMetaTarget, buildFieldMetadata } from "./utils/fieldMeta.utils";

export const DealLedgerSchema = Type.Object({
  AppraisalDepositAmount: MoneyAmountField,
  AppraisalFeeAmount: MoneyAmountField,
  DeferredOriginationFeeAmount: Deprecated(MoneyAmountField),
  CreditReportFeeAmount: MoneyAmountField,
  InterestReserveAmount: MoneyAmountField,
  NewYorkProcessingFeeAmount: MoneyAmountField,
  LenderCounselFeeAmount: MoneyAmountField,
  TotalBrokerFeeAmount: MoneyAmountField,
  ContractorReviewFeeAmount: MoneyAmountField,
  FeasibilityReviewFeeAmount: MoneyAmountField,
  CondoCertificationFeeAmount: MoneyAmountField
});

export const BaseMilestoneData = <TType extends DealMilestoneType>(
  type: TType
) =>
  Type.Object({
    type: Type.Literal(type),
    timestamp: Type.String()
  });

const MilestoneChangeReasonData = <TType extends DealMilestoneType>(
  type: TType
) =>
  Type.Intersect([
    BaseMilestoneData(type),
    Type.Object({
      genericReason: Type.String(),
      specificReason: Type.String()
    })
  ]);

export const DeadDealMilestoneData = MilestoneChangeReasonData(
  DealMilestoneType.DeadDeal
);

export const SuspendedMilestoneData = MilestoneChangeReasonData(
  DealMilestoneType.Suspended
);

const AllowEmptyObject = (milestoneData: DealMilestoneType) =>
  Type.Optional(
    Type.Intersect([BaseMilestoneData(milestoneData), Type.Object({})])
  );

export const DealMetadataSchema = Type.Object({
  milestone: EnumField(DealMilestoneType),
  organizations: ArrayField(StringField),
  branches: ArrayField(StringField),
  teams: ArrayField(StringField),
  activeUsers: ArrayField(StringField),
  milestoneData: Type.Optional(
    Type.Object({
      [DealMilestoneType.DeadDeal]: Type.Optional(
        Type.Intersect([DeadDealMilestoneData, Type.Object({})])
      ),
      [DealMilestoneType.Suspended]: Type.Optional(
        Type.Intersect([SuspendedMilestoneData, Type.Object({})])
      ),
      [DealMilestoneType.ClearToClose]: AllowEmptyObject(
        DealMilestoneType.ClearToClose
      ),
      [DealMilestoneType.CloseOfEscrow]: AllowEmptyObject(
        DealMilestoneType.CloseOfEscrow
      ),
      [DealMilestoneType.FileComplete]: AllowEmptyObject(
        DealMilestoneType.FileComplete
      ),
      [DealMilestoneType.FinalClosingUpdatesRequested]: AllowEmptyObject(
        DealMilestoneType.FinalClosingUpdatesRequested
      ),
      [DealMilestoneType.FinalDealReview]: AllowEmptyObject(
        DealMilestoneType.FinalDealReview
      ),
      [DealMilestoneType.FinalDealReviewApproved]: AllowEmptyObject(
        DealMilestoneType.FinalDealReviewApproved
      ),
      [DealMilestoneType.FinalQuoteAccepted]: AllowEmptyObject(
        DealMilestoneType.FinalQuoteAccepted
      ),
      [DealMilestoneType.FinalQuoteIssued]: AllowEmptyObject(
        DealMilestoneType.FinalQuoteIssued
      ),
      [DealMilestoneType.Funded]: AllowEmptyObject(DealMilestoneType.Funded),
      [DealMilestoneType.InitialDealReview]: AllowEmptyObject(
        DealMilestoneType.InitialDealReview
      ),
      [DealMilestoneType.LoanPackageReceived]: AllowEmptyObject(
        DealMilestoneType.LoanPackageReceived
      ),
      [DealMilestoneType.LoanPackageSent]: AllowEmptyObject(
        DealMilestoneType.LoanPackageSent
      ),
      [DealMilestoneType.NewDeal]: AllowEmptyObject(DealMilestoneType.NewDeal),
      [DealMilestoneType.Processing]: AllowEmptyObject(
        DealMilestoneType.Processing
      )
    })
  )
});

export const AttorneySchema = Type.Object({
  FullName: StringField
});

export const TitleCompanySchema = Type.Object({
  Attorney: Type.Partial(AttorneySchema),
  FullName: StringField,
  AddressLineText: StringField,
  AddressUnitIdentifier: StringField,
  CityName: StringField,
  StateName: StateNameSchema,
  StateCode: StateCodeSchema,
  PostalCode: PostalCodeField,
  CountyName: StringField,
  ClosingAgentFirstName: StringField,
  ClosingAgentLastName: StringField,
  ClosingAgentContactPointTelephoneValue: PhoneNumberField,
  ClosingAgentContactPointEmailValue: EmailField,
  TitleInsuranceUnderwriter: StringField
});

export const TitleCommitmentSchema = Type.Object({
  TitleEffectiveDate: DateField,
  TitleExpirationDate: DateField,
  TitlePolicyAmount: MoneyAmountField,
  TitleVestingType: EnumField(TitleVestingType),
  AllSubjectPropertiesIncludedIndicator: BooleanField,
  AllPayoffsLiensIncludedInSection1RequirementsIndicator: BooleanField,
  RequiredPayoffsLiensNotIncludedInSection2ExceptionsIndicator: BooleanField,
  TitleInternationalExecutionIndicator: BooleanField,
  LeaseholdEndorsementIndicator: BooleanField,
  TitleCommitmentComments: StringField,
  PropertyRightsOwnershipType: EnumField(PropertyRightsOwnershipType)
});

export const GeneralContractorSchema = Type.Object({
  FullName: StringField,
  RepresentativeFirstName: StringField,
  RepresentativeLastName: StringField,
  ContactPointTelephoneValue: PhoneNumberField,
  ContactPointEmailValue: EmailField,
  CapitalExpenditureAmount: MoneyAmountField
});

export const BrokerSchema = Type.Object({
  FullName: StringField,
  RepresentativeFirstName: StringField,
  RepresentativeLastName: StringField,
  ContactPointTelephoneValue: PhoneNumberField,
  ContactPointEmailValue: EmailField,
  NMLSIdentifier: StringField,
  BrokerWiringInstructionsIndicator: BooleanField
});

export const PropertyManagementSchema = Type.Object({
  PropertyManagerType: EnumField(PropertyManagerType),
  FullName: StringField,
  RepresentativeFullName: StringField,
  FullAddressName: StringField,
  ContactPointTelephoneValue: PhoneNumberField,
  ContactPointEmailValue: EmailField,
  PropertyManagementComments: StringField
});

export const DealPropertyInsuranceAgencySchema = Type.Object({
  FullName: StringField
});

export const DealFloodInsuranceAgencySchema = Type.Object({
  FullName: StringField
});

export const LenderSchema = Type.Object({
  FormationState: StateNameSchema,
  EntityType: EnumField(LenderEntityType),
  AddressLineText: StringField,
  AddressUnitIdentifier: StringField,
  CityName: StringField,
  CountyName: StringField,
  StateName: StateNameSchema,
  StateCode: StateCodeSchema,
  PostalCode: PostalCodeField
});
export const LenderFinanceSchema = Type.Object({
  PartnerFirstName: StringField,
  PartnerLastName: StringField,
  PartnerInitialTermSheetApprovalStatusIndicator: BooleanField,
  PartnerInitialTermSheetApprovalStatusDate: DateField,
  PartnerFinalTermSheetApprovalStatusDate: DateField,
  BorrowerFinalTermSheetApprovalStatusDate: DateField
});

export const CreditReviewCommitteeSchema = Type.Object({
  creditMemoDraftedDate: DateField,
  creditMemoApprovedDate: DateField,
  internalSubmissionDate: DateField,
  internalApprovalDate: DateField,
  externalSubmissionDate: DateField,
  externalApprovalDate: DateField,
  lOIIssuedDate: DateField,
  lOIAcceptedDate: DateField
});

export const DealExceptionsSchema = Type.Object({
  MaxARVAmount: Deprecated(Type.Partial(FieldExceptionTemplateSchema)),
  BlendedLTCRatePercent: Deprecated(Type.Partial(FieldExceptionTemplateSchema)),
  LTCRatePercent: Deprecated(Type.Partial(FieldExceptionTemplateSchema)),
  ConstructionLTCRatePercent: Deprecated(
    Type.Partial(FieldExceptionTemplateSchema)
  ),
  ConstructionLTCLoanAmount: Deprecated(
    Type.Partial(FieldExceptionTemplateSchema)
  ),
  MaxLTVLoanAmount: Deprecated(Type.Partial(FieldExceptionTemplateSchema)),
  MaxLTARVRatePercent: Deprecated(Type.Partial(FieldExceptionTemplateSchema)),
  MaxLTARVAmount: Deprecated(Type.Partial(FieldExceptionTemplateSchema)),
  ExecutionType: Deprecated(Type.Partial(FieldExceptionTemplateSchema)),
  DefaultInterestRatePercent: Deprecated(
    Type.Partial(FieldExceptionTemplateSchema)
  ),
  BlendedLTCLoanAmount: Deprecated(Type.Partial(FieldExceptionTemplateSchema)),
  ExceptionApprovedBy: EnumField(DealExceptionApprovedBy),
  ExceptionCommentary: StringField,
  ExceptionType: ArrayField(EnumField(ExceptionType)),
  PolicySection: ArrayField(EnumField(ExceptionPolicy))
});

const fieldMetaSchema = buildFieldMetadata<Deal, TObject<TProperties>>({
  entityTemplate: getDealTemplate(),
  target: FieldMetaTarget.Schema
});

export const DealFieldsSchema = Type.Object({
  FieldMeta: Type.Partial(fieldMetaSchema),
  DealMetadata: Type.Partial(DealMetadataSchema),
  LOCAvailableAmount: MoneyAmountField,
  LOCExpirationDate: DateField,
  LTCRatePercent: Deprecated(PercentField),
  CashOutAmount: MoneyAmountField,
  DownPaymentAmount: MoneyAmountField,
  AggregateOccupancyPercent: PercentField,
  PolicySectionNumber: StringField,
  LenderIdentifier: EnumField(LenderIdentifierType),
  LoanIdentifier: StringField,
  ApplicationReceivedDate: DateField,
  LOCIssuanceDate: DateField,
  PreliminaryUnderwritingDecisionDate: DateField,
  FinalUnderwritingDecision: StringField,
  InitialTaskGenerationDate: DateField,
  ClearToCloseDate: DateField,
  LoanName: StringField,
  Channel: EnumField(ChannelType),
  RealEstateProgramType: EnumField(RealEstateProgramType),
  EmployeeLoanIndicator: BooleanField,
  LoanProgramType: EnumField(LoanProgramType),
  RecourseType: EnumField(RecourseType),
  WireReleaseDate: DateField,
  LoanFundingDate: DateField,
  aggregations: Type.Partial(
    Type.Object({
      ScheduledFirstPaymentDate: AggregationFieldSchema(DateField),
      ARMFinalAdjustableRateTermMonthCount:
        AggregationFieldSchema(IntegerField),
      ARMInitialFixedTermMonthCount: AggregationFieldSchema(IntegerField),
      PrepaymentPenaltyExpirationDate: AggregationFieldSchema(DateField),
      PrepaidInterestAmount: AggregationFieldSchema(MoneyAmountField),
      LoanMaturityDate: AggregationFieldSchema(DateField),
      ARMRepriceFrequencyType: AggregationFieldSchema(
        EnumField(ARMRepriceFrequencyType)
      ),

      TotalHOAFeesAmount: AggregationFieldSchema(MoneyAmountField),
      TotalInitialInsuranceEscrowAmount:
        AggregationFieldSchema(MoneyAmountField),
      TotalAsIsAppraisedValueAmount: AggregationFieldSchema(MoneyAmountField),
      TotalAssignmentFeeAmount: AggregationFieldSchema(MoneyAmountField),
      TotalBudgetAmount: AggregationFieldSchema(MoneyAmountField),
      TotalFloodInsurancePremiumAmount:
        AggregationFieldSchema(MoneyAmountField),
      TotalInitialTaxEscrowAmount: AggregationFieldSchema(MoneyAmountField),
      TotalOutstandingLoanPayoffAmount:
        AggregationFieldSchema(MoneyAmountField),
      TotalPropertyInsurancePremiumAmount:
        AggregationFieldSchema(MoneyAmountField),
      TotalPurchasePriceAmount: AggregationFieldSchema(MoneyAmountField),
      TotalSubjectToAppraisedValueAmount:
        AggregationFieldSchema(MoneyAmountField),
      TotalTaxAmount: AggregationFieldSchema(MoneyAmountField),
      TotalCostAmount: AggregationFieldSchema(MoneyAmountField),
      TotalFloodInsurancePremiumOutstandingAmount:
        AggregationFieldSchema(MoneyAmountField),
      RequestedLoanAmount: AggregationFieldSchema(MoneyAmountField),
      TotalMonthlyMarketRent: AggregationFieldSchema(MoneyAmountField),
      NetWireAmount: AggregationFieldSchema(MoneyAmountField),
      TotalPropertyInsurancePremiumOutstandingAmount:
        AggregationFieldSchema(MoneyAmountField),
      TotalNumberOfProperties: AggregationFieldSchema(NumberField),
      TotalAdjustedMonthlyRentAmount: AggregationFieldSchema(MoneyAmountField),
      TotalAnnualCapitalExpenditure: AggregationFieldSchema(MoneyAmountField),
      MonthlyPaymentOfCapitalExpenditure:
        AggregationFieldSchema(MoneyAmountField),
      EstimatedClosingDate: ThirdPartyFieldSchema(DateField),
      LoanProductType: ThirdPartyFieldSchema(EnumField(LoanProductType)),
      LoanProgramType: ThirdPartyFieldSchema(EnumField(LoanProgramType)),
      LastDayOfClosingMonth: AggregationFieldSchema(DateField),
      Integrations: Type.Object({
        [IntegrationType.WireInsuranceCertificate]: Type.Optional(
          Type.Object({
            [WireInsuranceVendorType.FundingShield]: Type.Optional(
              Type.Object({
                TransactionId: Type.Optional(
                  ThirdPartyFieldSchema(StringField)
                ),
                TransactionStatus: Type.Optional(
                  ThirdPartyFieldSchema(StringField)
                ),
                StateNYIndicator: Type.Optional(
                  AggregationFieldSchema(EnumField(BooleanSelectType))
                )
              })
            )
          })
        )
      }),
      PerDiemPrepaidInterestAmount: AggregationFieldSchema(MoneyAmountField)
    })
  ),
  quote: Type.Partial(
    Type.Object({
      aggregations: Type.Partial(
        Type.Object({
          OriginationFeeAmount: ThirdPartyFieldSchema(MoneyAmountFieldString),
          ProcessingFeeAmount: ThirdPartyFieldSchema(MoneyAmountFieldString),
          BrokerOriginationFeeAmount: ThirdPartyFieldSchema(
            MoneyAmountFieldString
          ),
          ServicingSetupFee: ThirdPartyFieldSchema(MoneyAmountFieldString),
          PricingEngineWarnings: ThirdPartyFieldSchema(StringField),
          BrokerProcessingFeeAmount: ThirdPartyFieldSchema(
            MoneyAmountFieldString
          ),
          FeeLLPAs: ThirdPartyFieldSchema(StringField),
          RateLLPAs: ThirdPartyFieldSchema(StringField),
          PricingEngineOverrides: ThirdPartyFieldSchema(StringField),
          ValidationErrors: ThirdPartyFieldSchema(StringField),
          QuoteId: ThirdPartyFieldSchema(StringField),
          LtvEligibility: ThirdPartyFieldSchema(StringField),
          InterestReserveEscrow: ThirdPartyFieldSchema(MoneyAmountFieldString),
          PrepaymentPenaltyType: ThirdPartyFieldSchema(
            EnumField(PrepaymentPenaltyType)
          ),
          NoteRatePercent: ThirdPartyFieldSchema(
            ThreeDecimalPercentFieldString
          ),
          MonthlyPrincipalInterestPaymentAmount: ThirdPartyFieldSchema(
            MoneyAmountFieldString
          ),
          MonthlyTaxInsurancePaymentAmount: ThirdPartyFieldSchema(
            MoneyAmountFieldString
          ),
          MonthlyHomeownersAssociationAmount: ThirdPartyFieldSchema(
            MoneyAmountFieldString
          ),
          TotalMonthlyPITIPaymentAmount: ThirdPartyFieldSchema(
            MoneyAmountFieldString
          ),
          DebtServiceCoverageRatioPercent: ThirdPartyFieldSchema(
            ThreeDecimalPercentFieldString
          ),
          BuyUpDownFee: ThirdPartyFieldSchema(MoneyAmountFieldString),
          UnderPropertyValuationAmount:
            AggregationFieldSchema(MoneyAmountField),
          UnderPropertyValuationTotalCost:
            AggregationFieldSchema(MoneyAmountField),
          PrimaryBorrowerCreditScore: AggregationFieldSchema(NumberField),
          StateNY: AggregationFieldSchema(StringField),
          InternalL1CRefinanceIndicator: AggregationFieldSchema(BooleanField),
          BorrowingEntity: AggregationFieldSchema(StringField),
          OverPropertyValuationAmount: AggregationFieldSchema(MoneyAmountField),
          TotalValuationOrPurchasePriceAmount:
            AggregationFieldSchema(MoneyAmountField),
          PropertyTypeCondoIndicator: AggregationFieldSchema(BooleanField),
          PropertyValuationAmountUnderIndicator:
            AggregationFieldSchema(BooleanField),
          USCitizenshipIndicator: AggregationFieldSchema(BooleanField),
          SeasonalRental: AggregationFieldSchema(BooleanField),
          PropertyTypeMultifamilyIndicator:
            AggregationFieldSchema(BooleanField),
          InitialMonthlyInterestOnlyPaymentAmount: ThirdPartyFieldSchema(
            MoneyAmountFieldString
          ),
          PricingEngineLTARVRatePercent:
            ThirdPartyFieldSchema(PercentFieldString),
          PrimaryGuarantor: AggregationFieldSchema(StringField),
          SumTotalStatementQualifyingBalance:
            AggregationFieldSchema(MoneyAmountField),
          AltTier: AggregationFieldSchema(BooleanField),
          LesserOfLotOrPurchase: AggregationFieldSchema(MoneyAmountField),
          DebtStatus: AggregationFieldSchema(EnumField(DebtStatusType)),
          PropertyAddressLineText: AggregationFieldSchema(StringField),
          PropertyCityName: AggregationFieldSchema(StringField),
          PropertyStateCode: AggregationFieldSchema(StringField),
          PropertyPostalCode: AggregationFieldSchema(StringField),
          PropertyType: AggregationFieldSchema(StringField),
          TotalLTVRatePercent: AggregationFieldSchema(PercentField),
          PropertyId: AggregationFieldSchema(StringField),
          BorrowerTier: AggregationFieldSchema(
            Type.Union([
              Type.Literal("0"),
              EnumField(FixNFlipNBridgePlusTierType)
            ])
          ),
          OriginalPurchaseDate: AggregationFieldSchema(DateField),
          BuyDownFeeAmount: ThirdPartyFieldSchema(MoneyAmountFieldString),
          BuyUpFeeReductionAmount: ThirdPartyFieldSchema(
            MoneyAmountFieldString
          ),
          FullyDrawnInterestOnlyPayment: ThirdPartyFieldSchema(
            MoneyAmountFieldString
          ),
          LenderFinanceProcessingFee: ThirdPartyFieldSchema(
            MoneyAmountFieldString
          ),
          LenderFinanceApplicationFee: ThirdPartyFieldSchema(
            MoneyAmountFieldString
          ),
          LenderFinanceDocumentFee: ThirdPartyFieldSchema(
            MoneyAmountFieldString
          ),
          LenderFinanceAppraisalFee: ThirdPartyFieldSchema(
            MoneyAmountFieldString
          ),
          LimaRateToBorrowerPercent: ThirdPartyFieldSchema(PercentFieldString),
          LimaOriginationFeeToBorrowerPercent:
            ThirdPartyFieldSchema(PercentFieldString),

          PricingEngineTotalLoanToAfterRepairValueOverridePercent:
            ThirdPartyFieldSchema(PercentFieldString),
          AchRequiredIndicator: ThirdPartyFieldSchema(BooleanField),
          AchExemptionStatus: ThirdPartyFieldSchema(
            EnumField(AchExemptionStatusType)
          ),
          PricingEngineTotalBlendedLoanToCostOverridePercent:
            ThirdPartyFieldSchema(PercentFieldString),
          PricingEngineTotalPurchaseLoanToCostOverridePercent:
            ThirdPartyFieldSchema(PercentFieldString),
          PricingEngineNoteRateOverridePercent:
            ThirdPartyFieldSchema(PercentFieldString),

          PricingEngineMaxLoanToAfterRepairValuePercent:
            ThirdPartyFieldSchema(PercentFieldString),
          PricingEngineMaxBlendedLoanToCostPercent:
            ThirdPartyFieldSchema(PercentFieldString),
          PricingEngineMaxPurchaseLoanToCostPercent:
            ThirdPartyFieldSchema(PercentFieldString),
          Margin: ThirdPartyFieldSchema(PercentFieldString),
          FloorPercent: ThirdPartyFieldSchema(PercentFieldString),
          LifetimeCapPercent: ThirdPartyFieldSchema(PercentFieldString),
          BlendedLTCAdjuster: ThirdPartyFieldSchema(IntegerField),
          PurchaseLTCAdjuster: ThirdPartyFieldSchema(IntegerField),
          ARVAdjuster: ThirdPartyFieldSchema(IntegerField),
          DeferredOriginationFeeAmount: ThirdPartyFieldSchema(
            MoneyAmountFieldString
          )
        })
      )
    })
  ),
  PaymentDueDay: IntegerField,
  NextPaymentDueDate: DateField,
  AmortizationType: EnumField(AmortizationType),
  ExecutionType: EnumField(ExecutionType),
  LoanPaymentType: EnumField(LoanPaymentType),
  LoanTermPeriodMonthCount: IntegerField,
  LoanAmortizationPeriodMonthCount: IntegerField,
  LoanInterestOnlyPeriodMonthCount: IntegerField,
  LoanAfterInterestOnlyPeriodMonthCount: IntegerField,
  InterestAccrualMethodType: EnumField(InterestAccrualMethodType),
  LoanAmortizationPeriodYearCount: IntegerField,
  IndexSourceType: EnumField(IndexSourceType),
  CapitalStructureType: EnumField(CapitalStructureType),
  MarginRatePercent: Deprecated(ThreeDecimalPercentField),
  FloorRatePercent: Deprecated(ThreeDecimalPercentField),
  LifetimeCapRatePercent: Deprecated(ThreeDecimalPercentField),
  ARMRepriceRoundingRatePercentType: EnumField(
    ARMRepriceRoundingRatePercentType
  ),
  ARMRepriceRoundingMethodType: EnumField(ARMRepriceRoundingMethodType),
  ARMRepriceLookbackType: EnumField(ARMRepriceLookbackType),
  UCCFiledIndicator: BooleanField,
  UCCExpirationDate: DateField,
  DefaultInterestRatePercent: PercentField,
  WarehouseBankType: EnumField(WarehouseBankType),
  WarehouseBankStatusType: EnumField(WarehouseBankStatusType),
  FundingShieldStatusType: EnumField(FundingShieldStatusType),
  HaircutAmount: MoneyAmountField,
  PostClosingNotes: StringField,
  CollateralPackageStatusType: EnumField(CollateralPackageStatusType),
  CollateralPackageTrackingNumber: StringField,
  Ledger: Type.Partial(DealLedgerSchema),
  LienPosition: StringField,
  TitleCompany: Type.Partial(TitleCompanySchema),
  BlendedLTCRatePercent: Deprecated(PercentField),
  BlendedLTCLoanAmount: MoneyAmountField,
  ConstructionLTCRatePercent: PercentField,
  ConstructionLTCLoanAmount: Deprecated(MoneyAmountField),
  TotalPropertyValuationAmount: MoneyAmountField,
  MaxLTVRatePercent: Deprecated(PercentField),
  MaxLTVLoanAmount: Deprecated(MoneyAmountField),
  MaxARVAmount: MoneyAmountField,
  MaxLTARVRatePercent: Deprecated(PercentField),
  MaxLTARVAmount: Deprecated(MoneyAmountField),
  InitialAdvancedFundAmount: MoneyAmountField,
  TotalLoanFeesAndClosingCostAmount: MoneyAmountField,
  ClosingAnalystUserId: StringField,
  PromissoryNoteComments: StringField,
  ShippingCompanyType: EnumField(ShippingCompanyType),
  NoteToWarehouseTrackingNumber: StringField,
  SettlementStatementFileNumber: StringField,
  PersonalGuarantorsCount: IntegerField,
  PowerOfAttorneyFullName: StringField,
  AllongeOrAssignmentSentToWarehouseIndicator: BooleanField,
  GeneralContractor: Type.Partial(GeneralContractorSchema),
  Broker: Type.Partial(BrokerSchema),
  Lender: Type.Partial(LenderSchema),
  PropertyModelType: EnumField(PropertyModelType),
  LastPaymentDueDate: DateField,
  GrossSpreadPercent: PercentField,
  GracePeriodDays: IntegerField,
  LetterOfIntentSignedDate: DateField,
  ReleasePercent: PercentField,
  LateFeePercent: PercentField,
  Exceptions: Type.Partial(DealExceptionsSchema),
  PromissoryNoteRequestedIndicator: BooleanField,
  PromissoryNoteShippedIndicator: BooleanField,
  PromissoryNoteTrackingNumber: StringField,
  AllongeAssignmentSentToWarehouseIndicator: BooleanField,
  AllongeAssignmentTrackingNumber: StringField,
  NotaryUserId: StringField,
  NotaryExpirationDate: DateField,
  LoanPackageTrackingNumber: StringField,
  LoanPackageNoteTrackingNumber: StringField,
  FundingAndDisbursementApprovalIndicator: BooleanField,
  LoanPackageInternationalExecutionIndicator: BooleanField,
  LoanPackageWireRequestComments: StringField,
  LoanPackageNoteReceivedByLenderIndicator: BooleanField,
  LoanPackageChecksReceivedIndicator: BooleanField,
  LoanPackageCheckNumber: StringField,
  LoanPackageCheckAmount: MoneyAmountField,
  ChargentTransactionIdentifier: StringField,
  ChargentGatewayIdentifier: StringField,
  CRMId: StringField,
  OperationsDepartment: EnumField(DealOperationsDepartmentType),
  ChargentCollectionAmount: MoneyAmountField,
  ValuationFeesCollectedBySalesIndicator: BooleanField,
  TotalLTARVRatePercent: PercentField,
  InitialLTVRatePercent: PercentField,
  InitialMonthlyInterestOnlyPaymentAmount: PercentField,
  MonthlyPrincipalInterestPaymentAmount: PercentField,
  PropertyInsurancePremiumPaymentType: EnumField(
    PropertyInsurancePremiumPaymentType
  ),
  FloodInsurancePremiumPaymentType: EnumField(FloodInsurancePremiumPaymentType),
  PropertyInsuranceAgency: Type.Partial(DealPropertyInsuranceAgencySchema),
  FloodInsuranceAgency: Type.Partial(DealFloodInsuranceAgencySchema),
  InterestReserveDepositAmount: MoneyAmountField,
  DeferredMaintenanceAmount: MoneyAmountField,
  TitleCommitment: Type.Partial(TitleCommitmentSchema),
  TotalLiquidAssetAmount: MoneyAmountField,
  SettlementStatementMatchLedgerIndicator: BooleanField,
  LoanAmountVerifiedMatchIndicator: BooleanField,
  ConstructionBudgetVerifiedMatchIndicator: BooleanField,
  SettlementStatementComments: StringField,
  EOExpirationDate: DateField,
  EONamedInsuredMatchIndicator: BooleanField,
  WireABARoutingNumber: StringField,
  WireAccountNumber: StringField,
  WireBankName: StringField,
  WireAccountName: StringField,
  TitleFormsComments: StringField,
  AssetSummaryReportIndicator: Deprecated(BooleanField),
  CreditMemorandumIndicator: Deprecated(BooleanField),
  CreditReviewCommitteeApprovalIndicator: Deprecated(BooleanField),
  CreditReviewCommitteeComments: Deprecated(StringField),
  PricingEngineExceptionStatus: EnumField(PricingEngineExceptionStatusType),
  ProgramDirectorApprovalIndicator: BooleanField,
  ProgramDirectorComments: StringField,
  SubmissionNotes: StringField,
  UnderwriterApprovalComments: StringField,
  UnderwriterApprovalStatus: EnumField(UnderwriterApprovalStatusType),
  PortfolioLoanIndicator: BooleanField,
  PropertyManagement: Type.Partial(PropertyManagementSchema),
  LoanPurposeType: EnumField(LoanPurposeType),
  RateLockStartDate: DateField,
  RateLockEndDate: DateField,
  TotalCashToFromBorrower: MoneyAmountField,
  CashFromBorrowerAmount: MoneyAmountField,
  CashToBorrowerAmount: MoneyAmountField,
  MarketingPromotionCreditAmount: Deprecated(MoneyAmountField),
  MarketingPromotionCode: Deprecated(StringField),
  LenderFinance: Type.Partial(LenderFinanceSchema),
  ExitStrategy: StringField,
  TargetCloseDate: DateField,
  ApplicationRequestedLoanAmount: MoneyAmountField,
  ProjectAnticipatedCompletionTimeline: StringField,
  ConstructionBudgetRemainingAmount: MoneyAmountField,
  VerifiableConstructionCompletedAmount: MoneyAmountField,
  LienDollarAmount: MoneyAmountField,
  PropertyTypeChangingIndicator: BooleanField,
  PropertyZoningChangesIndicator: BooleanField,
  SubdividePropertyPartialReleasesIndicator: BooleanField,
  LenderTitleProviderIndicator: BooleanField,
  LenderPropertyInsuranceProviderIndicator: BooleanField,
  HOAContactName: StringField,
  HOAContactPhone: PhoneNumberField,
  HOAContactEmail: EmailField,
  Occupancy: PercentField,
  PropertyRentReadyConditionIndicator: BooleanField,
  PropertyLeasePurchaseOptionIndicator: BooleanField,
  OccupationIndicator: BooleanField,
  SellerRelationshipIndicator: BooleanField,
  AssociatedPropertiesDescription: StringField,
  DevelopmentStrategy: StringField,
  LotStatus: StringField,
  CurrentLotMarketValueAmount: MoneyAmountField,
  LotZonedParcelIdIndicator: BooleanField,
  BuildingPermitsIndicator: BooleanField,
  BuildingPermitsExpectedDate: DateField,
  LotUtilitiesIndicator: BooleanField,
  BuildingEnvelopeExpandedIndicator: BooleanField,
  LoadBearingWallsRemoveIntendedIndicator: BooleanField,
  SquareFootageAddedIndicator: BooleanField,
  InterestReserveEscrowOverrideMonthCount: IntegerField,
  InterestReserveEscrowOverrideAmount: MoneyAmountField,
  ProcessingFeeOverride: MoneyAmountField,
  Integrations: Type.Optional(
    Type.Object({
      [IntegrationType.WireInsuranceCertificate]: Type.Optional(
        Type.Object({
          selectedVendor: Type.Optional(EnumField(WireInsuranceVendorType)),
          [WireInsuranceVendorType.FundingShield]: Type.Optional(
            Type.Object({
              userEmail: Type.Optional(EmailField),
              userFirstName: Type.Optional(StringField),
              userLastName: Type.Optional(StringField),
              rushOrderIndicator: Type.Optional(BooleanField)
            })
          )
        })
      )
    })
  ),
  CreditReviewCommittee: Type.Partial(CreditReviewCommitteeSchema),
  ACHElectedIndicator: BooleanField,
  SellerHUDAddedIndicator: BooleanField,
  PurchaserWarrantyDeedAddedIndicator: BooleanField,
  MailAwayIndicator: BooleanField,
  FundingAgentEmailAddress: EmailField,
  WireInsuranceMatchDealIndicator: BooleanField,
  RecordedMortgageReceivedDate: DateField,
  TitlePolicyReceivedDate: DateField,
  RecordedWarrantyDeedReceivedDate: DateField,
  AppraisalNotes: StringField
});
export const DealSchema = Type.Intersect([BaseEntitySchema, DealFieldsSchema]);

export const DealUpdate = OmitUpdateSchema(DealSchema);
export const DealCreate = OmitCreateSchema(DealSchema);

export const getOperationDealSchema = (
  op: "create" | "update"
): TObject<TProperties> => {
  if (op === "create") {
    return DealCreate;
  } else if (op === "update") {
    return DealUpdate;
  }
  throw `${op} is not supported`;
};
